html,
body {
  background-color: #ffffff;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-corner {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f5f4f1 inset;
}

#loader {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@font-face {
  font-family: "Anek Bangla";
  font-weight: 400;
  padding: 0;
  font-display: swap;
  src: local("Anek Bangla"),
    url("assets/fonts/anekbangla.woff2") format("woff2");
}

@font-face {
  font-family: "Anek Bangla";
  font-weight: 700;
  padding: 0;
  font-display: swap;
  src: local("Anek Bangla"),
    url("assets/fonts/anekbangla-bold.woff2") format("woff2");
}

@font-face {
  font-family: "Mulish";
  font-weight: 400;
  padding: 0;
  font-display: swap;
  src: local("Mulish"), url("assets/fonts/mulish.woff2") format("woff2");
}

@font-face {
  font-family: "Mulish";
  font-weight: 700;
  padding: 0;
  font-display: swap;
  src: local("Mulish"), url("assets/fonts/mulish-bold.woff2") format("woff2");
}

body {
  font-family: "Anek Bangla", "Mulish", sans-serif;
}
